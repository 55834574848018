
export function loc2jquery(loc) {
    if (loc.jquery) return loc;
    if (loc instanceof Element) {
        // DOM element
        return $(loc);
    }
    if (typeof loc === 'string') {
        // id
        return $(loc);
    }
}

export function loc2dom(loc) {
    console.log('loc2dom', loc)
    if (loc.jquery) return loc[0];
    if (loc instanceof Element) return loc;
    if (typeof loc === 'string') return document.querySelector(loc);
    return 42;
    throw "don't know what loc is..."
}
