/* global Highcharts */

import {loc2dom, loc2jquery} from "./locations";

/**
 * Usage:

 <div id="my-gauge"></div>
 <script>
 dkcharts.Gauge('#my-gauge', {
                size: 100,                          // size of the chart
                value: 138,                         // current value of the gauge
                color: 'lightseagreen',             // color of the gauge (optional)
                title: `(av ${exam_total})`,        // title of the chart (optional)
                range: [0, 202]                     // domain of the value (value must be inside range)
            });
 </script>
 */
export class Gauge {
    constructor(location, options) {
        const loc = loc2dom(location);
        const jqloc = loc2jquery(location);
        const size = options.size || 1000;
        const font_size = size > 100 ? size * 0.35 : size * 0.30;
        const value = options.value || 0;
        this.graph_size = size;
        this.title_value = options.title;
        this.lo = options.range ? options.range[0] : 0;
        this.hi = options.range ? options.range[1] : 100;
        this._raw_value = value;
        this.color = options.color || null;
        this.name = options.name || null;
        this.tooltip = options.tooltip || false;
        this.show_legend = options.show_legend || false;

        loc.style.width = `${size}px`;
        loc.style.height = `${size}px`;
        loc.classList.add('dkcharts', 'dkcharts-gauge');
        if (this.color) loc.style.setProperty('--data-color-1', this.color);

        const params = {
            chart: {
                type: 'solidgauge',
                styledMode: true,
            },

            title: null,
            subtitle: null,
            // subtitle: {
            //     verticalAlign: 'bottom',
            //     floating: true,
            //     y: 10,
            //     widthAdjust: 0,
            //     style: {
            //         fontSize: `${Math.max(11, font_size/4)}px`
            //         // color: '#ffffff'
            //         // color: '#333333'
            //     },
            //     text: options.title,
            // },
            //
            // title: {
            //     verticalAlign: "bottom",
            //     floating: true,
            //     y: size/4,
            //     style: {
            //         fontSize: `${font_size}px`,
            //         letterSpacing: '-0.2ex'
            //     },
            //     text: `${value}`
            // },

            pane: {
                size: '100%',
                startAngle: -120,
                endAngle: 120,
                background: {
                    innerRadius: '92%',
                    outerRadius: '96%',
                    shape: 'arc'
                }
            },

            exporting: {enabled: options.enable_export || false},
            tooltip: {enabled: this.tooltip},
            credits: {enabled: false},
            plotOptions: {},

            yAxis: {
                stops: [
                    [1, options.color]
                ],
                lineWidth: 0,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                min: 0,
                max: 100,
                labels: {enabled: false}
            },

            series: [{
                name: this.name,
                data: [Math.round(this.value_to_percent(value) * 10) / 10],
                innerRadius: '88%',
                outerRadius: '100%',
                dataLabels: {enabled: false},
                tooltip: {
                    valueSuffix: ' %',
                    followPointer: true,
                },
                showInLegend: this.show_legend,
            }]
        };

        this.chart = Highcharts.chart(loc, params);

        this.value_box = $(`<div class="highcharts-title">${this.lo}</div>`);
        this.value_box.css({
            fontSize: `${font_size}px`,
            top: size / 4,
            width: size
        });

        this.title_box = $(`<div class="highcharts-subtitle">${this.get_title()}</div>`);
        this.title_box.css({
            fontSize: `${Math.max(11, font_size / 4)}px`,
            bottom: size / 10,
            width: size
        });


        // subtitle: {
        //     verticalAlign: 'bottom',
        //     floating: true,
        //     y: 10,
        //     widthAdjust: 0,
        //     style: {
        //         fontSize: `${Math.max(11, font_size/4)}px`
        //         // color: '#ffffff'
        //         // color: '#333333'
        //     },
        //     text: options.title,
        // },

        // location -> jquery
        jqloc.append(this.value_box);
        jqloc.append(this.title_box);
        this.update_value_box(this.lo, this._raw_value);
    }

    get_title() {
        if (this.title_value) {
            if (typeof this.title_value === 'function') {
                return this.title_value({
                    max: this.hi,
                    min: this.lo,
                    value: this.value
                });
            } else {
                return this.title_value;
            }
        } else {
            return "";
        }
    }

    update_title() {
        this.title_box.html(this.get_title());
    }

    get title() {
        return this.get_title();
    }

    set title(val) {
        this.title_value = val;
        this.update_title();
    }

    get size() {
        return this.graph_size;
    }

    set size(s) {
    }

    /*
    Animate value changes.
     */
    update_value_box(oldval, newval) {
        const updatefn = (v) => {
            this.value_box.html(v);
            if (v === newval) return;
            const step = Math.max(1, Math.ceil(Math.log2(Math.abs(newval - v))));
            const nextval = v + (v < newval ? step : -step);
            setTimeout(() => updatefn(nextval), 10);
        };
        updatefn(oldval);
    }

    get value() {
        return this._raw_value;
    }

    set value(val) {
        const oldval = this._raw_value;
        this._raw_value = val;
        this.chart.series[0].points[0].update(Math.round(this.value_to_percent(val) * 10) / 10);
        this.update_value_box(oldval, val);
    }

    value_to_percent(val) {
        return 100 * (val - this.lo) / (this.hi - this.lo);
    }

    percent_to_value(pct) {
        return (pct * (this.hi - this.lo)) + this.lo;
    }

    get range() {
        return [this.lo, this.hi];
    }

    set range([min, max]) {
        this.lo = min;
        this.hi = max;
        // not needed...?
        this.chart.yAxis[0].update({
            min: 0,
            max: 100
        });
        this.value = this.value;
        this.update_title();
    }

    get min() {
        return this.lo;
    }

    set min(v) {
        this.lo = v;
        // not needed...?
        this.chart.yAxis[0].update({min: 0});
        this.value = this.value;
        this.update_title();
    }

    get max() {
        return this.hi;
    }

    set max(v) {
        this.hi = v;
        // this.chart.yAxis[0].update({min: this.min, max: v});
        this.value = this.value;
        this.update_title();
    }

    set total(val) {
        this.min = 0;
        this.max = val;
        this.update_title();
    }
}
