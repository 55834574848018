
import {Gauge} from "./gauge";
import {PercentPie} from "./pie";
import {PercentPieLegend} from "./pie";
import {Histogram} from "./histogram";
import {string} from "./string_template";
import {loc2dom, loc2jquery} from "./locations";
import {ColumnChart} from "./columnchart";
import {BasicColumn} from "./columnchart";
import {BasicLine} from "./line";
import {BasicBar} from "./bar";


const dkcharts = {
    Gauge,
    PercentPie,
    PercentPieLegend,
    Histogram,
    ColumnChart,
    BasicColumn,
    BasicLine,
    BasicBar,
    string,
    loc2jquery,
    loc2dom,
};

export default dkcharts;
