/*
<div id="mybar"></div>

<script>
    const example = new dkcharts.BasicBar('#mybar', {
        column_names: ['2020', '2021'],
        xlabel: "år",
        ylabel: "%",
        values: OrderedDict(
            [('Column 1', {2020: '88.33', 2021: '90.84'}),
            ('Column 2', {2020: '79.33', 2021: '74.20'}),
            ('Column 3', {2020: '79.33', 2021: '75.98'}),
            ('Column 4', {2020: '74.67', 2021: '75.04'}),
            ('Column 5', {2020: '68.00', 2021: '76.79'}),
            ('Column 6', {2020: '89.53', 2021: '88.16'})]
            ),
        width: 1000,
        height: 500,
    })
</script>

*/

import {loc2dom, loc2jquery} from "./locations";

export class BasicBar {
    constructor(location, options) {
        const loc = loc2dom(location);
        const width = options.width || options.size || 1000;
        const height = options.height || options.size || 1000;
        loc.style.width = `${width}px`;
        loc.style.height = `${height}px`;
        loc.classList.add('dkcharts', 'dkcharts-basicbar');

        this.column_names = options.column_names || [];
        this.values = options.values || [];

        const params = {
            chart: {
                type: 'bar'
            },
            title: {
                text: options.title
            },
            subtitle: {
                text: options.subtitle,
            },
            xAxis: {
                categories: this.column_names,
                title: {
                    text: options.xlabel,
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: options.ylabel,
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: options.value_suffix,
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: this.valuesToSeries(this.values),
        };
        if (options.legend) {params.legend = options.legend}
        this.chart = Highcharts.chart(loc, params);
    }

    valuesToSeries(vals) {
        return Object.entries(vals).map(([k, v]) => this.valueToSerie(k, v));
    }

    valueToSerie(title, val) {
        let test = this.column_names.map(name => val[name])
        return {
            name: title,
            data: this.column_names.map(name => val[name])
        };
    }
}
