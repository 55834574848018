
export const sum = arr => arr.reduce((a, b) => a + b);
export const avg = arr => sum(arr) / arr.length;

// population stddev
export const stddev = arr => {
    const mean = avg(arr);
    return Math.sqrt(sum(arr.map(x => Math.pow(x - mean, 2))) / arr.length);
};

export const stddev_sample = arr => {
    const mean = avg(arr);
    return Math.sqrt(sum(arr.map(x => Math.pow(x - mean, 2))) / (arr.length-1));
};

export const Q1 = arr => avg(arr.sort().slice(0, arr.length / 2));
export const Q3 = arr => avg(arr.sort().slice(arr.length / 2));
export const Q2 = avg;
export const IQR = arr => Q3(arr) - Q1(arr);

export const zip = rows => rows[0].map((_, c) => rows.map(row => row[c]));

// export const bucketnames = (count, size) => [...Array(count).keys()].map(i => `${i*size}-${(i+1)*size}`);
export const bucketnames = (count, size) => [...Array(count).keys()].map(i => size === 1 ? `${i}` : `${i*size}-${(i+1)*size}`);

export function count_bucketvals(bincount, binsize, values, lo, hi) {
    const bucketvals = [...Array(bincount).keys()].map(_ => 0);
    values.forEach(v => {
        if (v === hi) {
            ++bucketvals[bucketvals.length - 1];
        } else {
            ++bucketvals[Math.floor(v/binsize)];
        }
    });
    return bucketvals;
}

export function assign_bucketvals(bincount, binsize, values, lo, hi) {
    const bucketvals = [...Array(bincount).keys()].map(_ => []);
    values.forEach(v => {
        if (v === hi) {
            bucketvals[bucketvals.length - 1].push(v);
        } else {
            bucketvals[Math.floor(v/binsize)].push(v);
        }
    });
    return bucketvals.map(v => v.sort((a, b) => a - b));
}


export const combine_buckets = (names, vals) => Object.fromEntries(zip([names, vals]));

export function range (min, max, values) {
    const low = min != null ? min : Math.min(...values);
    const hi = max != null ? max : Math.max(...values);
    return [low, hi];
}

export const checkbins = (count, size, max) => (count * size) - max;


export function count_buckets(values, bincount, binsize, low, hi) {
    return combine_buckets(
        bucketnames(bincount, binsize),
        count_bucketvals(bincount, binsize, values, low, hi)
    );
}


export function assign_buckets(values, bincount, binsize, low, hi) {
    return combine_buckets(
        bucketnames(bincount, binsize),
        assign_bucketvals(bincount, binsize, values, low, hi)
    );
}

